import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { SHOW_SEARCH_BAR, propTypes } from '../../../../util/types';
import {
  InlineTextButton,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  Modal,
  IconCollection,
  Button,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';
import AuthenticationForm from '../../../../components/AuthenticationForm/AuthenticationForm';
import { phoneNumberValidation } from '../../../../util/validators';

const TopbarDesktop = props => {
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [otp, setOtp] = useState(null);

  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    initialSearchFormValues,
    currentSearchParams,
    onCreateUserWithDots,
    createUserInProgress,
    createUserSuccess,
    createUserError,
    onVerifyUserWithDots,
    verifyUserInProgress,
    verifyUserError,
  } = props;
  const { profile, email } = !!currentUser?.id && currentUser.attributes || {};
  const { protectedData, privateData, firstName, lastName } = profile || {}
  const { bagItems = [], likeItems = [], dots_user_Id } = protectedData || {};
  const { dotsEncryptedData } = privateData || {};

  const [mounted, setMounted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    setIsModalOpen(false);
  }, [isAuthenticated]);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <div className={css.topbarSearchWrapper}>
      <TopbarSearchForm
        className={css.searchLink}
        desktopInputRoot={css.topbarSearchWithLeftPadding}
        // onSubmit={onSearchSubmit}
        initialValues={initialSearchFormValues}
        currentSearchParams={currentSearchParams}
        appConfig={appConfig}
        currentPage={currentPage}
      />
    </div>
  );

  const showSearch = SHOW_SEARCH_BAR.includes(currentPage);

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'inProgress' : 'inProgress' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const loginRequriedModal = (
    <Modal
      id="loginFormRequriedModal"
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
      isLandingPage={false}
      usePortal
      contentClassName={css.modalContent}
      onManageDisableScrolling={() => { }}
    >
      <div>
        <AuthenticationForm isModalOpen={isModalOpen} />
      </div>
    </Modal>
  );


  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        {/* <Avatar className={css.avatar} user={currentUser} disableProfileLink /> */}
        <span><FormattedMessage id="TopbarDesktop.account" /></span>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'inProgress' : 'inProgress' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inbox" />
            {notificationDot}
          </NamedLink>
        </MenuItem>
        <MenuItem key="InboxOrderPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.orders" />
            {notificationDot}
          </NamedLink>
        </MenuItem>
        <MenuItem key="LoginRequiredPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('LoginRequiredPage'))}
            name="LoginRequiredPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.sellProduct" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="DotsPayoutPage">
          {dots_user_Id && currentUserHasListings ? <a
              className={classNames(css.yourListingsLink, currentPageClass('DotsPayoutPage'))}
              href={process.env.REACT_APP_DOTS_WALLET_URL}
              target='_blank'
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.yourWalletLink" />
            </a>
            // : <a
            //   className={classNames(css.yourListingsLink, currentPageClass('DotsPayoutPage'))}
            //   href={process.env.REACT_APP_DOTS_WALLET_URL}
            //   target='_blank'
            // >
            //   <span className={css.menuItemBorder} />
            //   <FormattedMessage id="TopbarDesktop.yourWalletLink" />
            // </a>
            : null
          }
        </MenuItem>
        {/* <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    // <NamedLink name="SignupPage" className={css.signupLink}>
    <span
      className={css.signup}
      onClick={() => {
        setIsModalOpen(true);
      }}
    >
      <FormattedMessage id="TopbarDesktop.signup" />
    </span>
    // </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <span
      className={css.loginLink}
      onClick={() => {
        setIsModalOpen(true);
      }}
    >
      <FormattedMessage id="TopbarDesktop.login" />
    </span>
  );

  const likeLink = isAuthenticatedOrJustHydrated ? (
    <NamedLink
      name="FavoritesPage"
      className={css.likeLink}
    >
      <IconCollection icon="likeIcon" />
      <span className={css.count}>{likeItems?.length}</span>
    </NamedLink>
  ) : (
    <span
      className={css.likeLink}
      onClick={() => {
        setIsModalOpen(true);
      }}
    >
      <IconCollection icon="likeIcon" />
      <span className={css.count}>{0}</span>
    </span>
  );

  const bagLink = isAuthenticatedOrJustHydrated ? (
    <NamedLink name="CartPage">
      <span
        className={css.bagLink}
      >
        <IconCollection icon="bagIcon" />
        <span className={css.count}>{bagItems?.length}</span>
      </span>
    </NamedLink>
  ) : (
    <span
      className={css.bagLink}
      onClick={() => {
        setIsModalOpen(true);
      }}
    >
      <IconCollection icon="bagIcon" />
      <span className={css.count}>{0}</span>
    </span>
  );

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        rowely.
      </NamedLink>
      {/* <LinkedLogo
        className={css.logoLink}
        layout="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      /> */}
      {currentPage !== "AlgoliaSearchPage" ? search : <div className={css.searchLink}></div>}
      <div className={css.linksWrapper}>
        {/* <NamedLink className={css.createListingLink} name="NewListingPage">
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.createListing" />
          </span>
        </NamedLink> */}
        {loginRequriedModal}
        {likeLink}
        {bagLink}
        {/* {inboxLink} */}
        {profileMenu}
        {/* {signupLink} */}
        {loginLink}
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
